import React from 'react';
import Helmet from 'react-helmet';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

const MetaData = ({ sitecoreContext }) => {
  const { metadata } = sitecoreContext;
  const metaTitle =
    sitecoreContext.route.fields.metaTitle &&
    sitecoreContext.route.fields.metaTitle.value !== ''
      ? sitecoreContext.route.fields.metaTitle.value
      : metadata.title;
  const metaDescription =
    sitecoreContext.route.fields.metaDescription &&
    sitecoreContext.route.fields.metaDescription.value !== ''
      ? sitecoreContext.route.fields.metaDescription.value
      : metadata.description;

  const MetaDataObj = [
    { name: 'title', content: metaTitle },
    { name: 'description', content: metaDescription },
    { name: 'keywords', content: metadata.keywords },
    {
      name: 'library',
      class: 'swiftype',
      'data-type': 'string',
      content: metadata.localeCode,
    },
    {
      name: 'st_description',
      class: 'swiftype',
      'data-type': 'string',
      content: metaDescription,
    },
    {
      name: 'st_keywords',
      class: 'swiftype',
      'data-type': 'string',
      content: metadata.keywords,
    },
    { property: 'og:locale', content: metadata.localeCode },
    { property: 'og:type', content: 'article' },
    { property: 'og:title', content: metadata.title },
    { property: 'og:description', content: metadata.description },
    { property: 'og:url', content: metadata.url },
    { property: 'og:site_name', content: metadata.siteName },
    { property: 'og:updated_time', content: metadata.lastUpdated },
    { property: 'fb:app_id', content: metadata.facebookAppId },
    { name: 'twitter:description', content: metadata.description },
    { name: 'twitter:title', content: metadata.title },
    { name: 'twitter:site', content: metadata.siteName },
    { name: 'twitter:creator', content: metadata.twitterHandle },
  ];

  if (metadata.featureImage) {
    MetaDataObj.push(
      { property: 'og:image', content: metadata.featureImage },
      { property: 'og:image:width', content: metadata.featuredWidth },
      { property: 'og:image:height', content: metadata.featuredHeight },
      { name: 'twitter:image', content: metadata.featureImage },
      { name: 'twitter:card', content: metadata.featureImage },
    );
  }
  if (
    sitecoreContext.route.fields.noIndex &&
    sitecoreContext.route.fields.noIndex.value
  ) {
    MetaDataObj.push({ name: 'robots', content: 'noindex' });
  }

  const metaLinks = [];
  if (metadata.urls) {
    metaLinks.push(
      <link key="canonical" rel="canonical" href={metadata.urls.canonical} />,
    );
    metadata.urls.alternate.forEach((alt) => {
      metaLinks.push(
        <link
          key={alt.lang}
          rel="alternate"
          href={alt.link}
          hrefLang={alt.lang}
        />,
      );
    });
  }

  return (
    <Helmet meta={MetaDataObj.filter((item) => item.content)}>
      <title>{metaTitle || 'Page'}</title>
      {metaLinks}
      <script type="application/ld+json">{metadata.schemaorg}</script>
    </Helmet>
  );
};
export default withSitecoreContext()(MetaData);
