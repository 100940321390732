import React from 'react';

const GtmScripts = ({ gtmIdGlobal, gtmIdRegional }) => {
  if (typeof window !== 'undefined') {
    if (!!gtmIdGlobal) {
      (function (w, d, s, l, i) {
        w[l] = w[l] || [];
        w[l].push({
          'gtm.start': new Date().getTime(),
          event: 'gtm.js',
        });
        const f = d.getElementsByTagName(s)[0],
          j = d.createElement(s),
          dl = l !== 'dataLayer' ? `&l=${l}` : '';
        j.async = true;
        j.src = `https://www.googletagmanager.com/gtm.js?id=${i}${dl}`;
        f.parentNode.insertBefore(j, f);
      })(window, document, 'script', 'dataLayer', gtmIdGlobal);
    }

    (function (w, d, s, l, i) {
      w[l] = w[l] || [];
      w[l].push({
        'gtm.start': new Date().getTime(),
        event: 'gtm.js',
      });
      const f = d.getElementsByTagName(s)[0],
        j = d.createElement(s),
        dl = l !== 'dataLayer' ? `&l=${l}` : '';
      j.async = true;
      j.src = `https://www.googletagmanager.com/gtm.js?id=${i}${dl}`;
      f.parentNode.insertBefore(j, f);
    })(window, document, 'script', 'dataLayer', gtmIdRegional);
  }

  return (
    <noscript>
      <iframe
        src={`https://www.googletagmanager.com/ns.html?id=${gtmIdRegional}`}
        height="0"
        width="0"
        title="Google Tag Manager 1"
        aria-hidden
        style={{ display: 'none', visibility: 'hidden' }}
      />
      {gtmIdGlobal && (
        <iframe
          src={`https://www.googletagmanager.com/ns.html?id=${gtmIdGlobal}`}
          height="0"
          width="0"
          title="Google Tag Manager 2"
          aria-hidden
          style={{ display: 'none', visibility: 'hidden' }}
        />
      )}
    </noscript>
  );
};

export default GtmScripts;
