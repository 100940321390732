import React, { createContext, useMemo, useState } from "react";

export const HeaderContext = createContext({
  pageScrollable: true,
  setPageScrollable: () => {},
  showMobileMenu: false,
  handleCloseMobileMenu: () => {},
  handleToggleMobileMenu: () => {},
  userId: null,
  setUserId: () => {},
  chatFields: null,
  handleLoadChatFields: () => {},
});

export const HeaderProvider = ({ children }) => {
  const [pageScrollable, setPageScrollable] = useState(true);
  const [showMobileMenu, setShowMobileMenu] = useState(false);

  // store chat data from header
  const [chatFields, setChatFields] = useState(null);

  // used to check whether a profile has been loaded
  const [userId, setUserId] = useState(null);

  const contextValue = useMemo(
    () => ({
      pageScrollable,
      setPageScrollable,
      showMobileMenu,
      handleCloseMobileMenu: () => setShowMobileMenu(false),
      handleToggleMobileMenu: () => setShowMobileMenu((prev) => !prev),
      userId,
      setUserId,
      chatFields,
      handleLoadChatFields: ({ chatConfig, miawChat, sfhcChat }) =>
        setChatFields({
          chatDisplay: chatConfig.ChatDisplay?.value,
          chatType: chatConfig.chatType?.value,
          chatRecipientOnly: chatConfig.ChatRecipientOnly?.value,
          chatAuthOnly: chatConfig.ChatAuthOnly?.value,
          chatFixedPagePaths: chatConfig.chatFixedPagePaths,
          chatFixedPageSetup: chatConfig.ChatFixedPageSetup?.value,
          chatData: {
            miawChat,
            sfhcChat,
          },
        }),
    }),
    [pageScrollable, showMobileMenu],
  );

  return (
    <HeaderContext.Provider value={contextValue}>
      {children}
    </HeaderContext.Provider>
  );
};

export const withHeaderContext = (Component) => (props) => (
  <HeaderContext.Consumer>
    {(context) => <Component {...props} headerContext={context} />}
  </HeaderContext.Consumer>
);
