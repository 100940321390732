import React from 'react';

const DataLayerInit = ({ route, context }) => {
  if (typeof window === 'undefined') {
    return null;
  }
  const getBreadcrumbs = () => {
    const breadcrumbComponent = route.placeholders.header.filter(
      (component) => {
        return (
          component.componentName && component.componentName === 'Breadcrumbs'
        );
      },
    );
    // if there's no breadcrumb component return route name
    if (breadcrumbComponent.length === 0) {
      return { title: { value: route.name } };
    }

    return breadcrumbComponent[0].fields.data.item;
  };
  const traverseBreadcrumbs = (item, categories = []) => {
    // console.log(categories)
    // categories[`subCategory${counter}`] = item.title.value
    // we want to reverse order of breadcrumbs
    if (item.title) {
      categories.unshift(item.title.value);
    }
    // if item has no parent we have traversed entire breadcrumbs list and we return categories
    if (!item.parent) {
      return {
        pageType: context.metadata.pageType,
        ...categories.reduce((acc, curr, index) => {
          acc[`subCategory${index + 1}`] = curr;
          return acc;
        }, {}),
      };
    }
    //otherwise we continue traversing breadcrumbs for categorie
    return traverseBreadcrumbs(item.parent, categories);
  };

  const categories = traverseBreadcrumbs(getBreadcrumbs());

  // Gathering the domain/subdomain
  const hostname = window.document.domain;
  const topLevel = new RegExp('.(com.au|co.uk|me|com|us|es|fr|it)');
  const tmpDomain = hostname.replace(topLevel, '');
  const _mainDomain = tmpDomain.split('.').pop();
  const _subDomain = tmpDomain.split(_mainDomain)[0];

  // Gathering sysEnv
  let envStr = '';
  if (
    navigator.userAgent.match(/Android/i) ||
    navigator.userAgent.match(/webOS/i) ||
    navigator.userAgent.match(/iPhone/i) ||
    navigator.userAgent.match(/iPod/i) ||
    navigator.userAgent.match(/BlackBerry/i) ||
    navigator.userAgent.match(/Windows Phone/i)
  ) {
    envStr = 'mobile';
  } else if (
    navigator.userAgent.match(/Mac/i) ||
    navigator.userAgent.match(/Linux/i) ||
    navigator.userAgent.match(/Win/i) ||
    navigator.userAgent.match(/Unix/i)
  ) {
    envStr = 'desktop';
  } else {
    envStr = 'tablet';
  }

  const eventName = 'DataLayer Ready';
  if (!window.dataLayer) {
    window.dataLayer = [];
  }
  window.dataLayer.push({
    event: eventName,
    page: {
      pageInfo: {
        pageID: route.itemId,
        pageTitle: route.name,
        pageURL: window.location.href,
        publicationDate: context.metadata.lastUpdated, //publish date not available use update date
        updatedDate: context.metadata.lastUpdated,
        domain: _mainDomain,
        subDomain: _subDomain,
        sysEnv: envStr, // “desktop”, “mobile”, “tablet”
        country: context.metadata.localeCode, // ISO 3166 recommended
        language: context.language,
      },
      category: categories,
    },
    user: {
      profileInfo: {
        visitorType: '', //”New”, “Returning”
        segment: '', // Definitions TBC
      },
    },
    // munchkin not used in any DLV
    // munchkin : {
    //   instanceId : MARKETO_INSTANCEID
    // }
  });
  return null;
};
export default DataLayerInit;
