import React from 'react';
import {
  Placeholder as JSSPlaceholder,
  withSitecoreContext,
} from '@sitecore-jss/sitecore-jss-react';

//Set of components which shouldn't be filtered out
const exceptionComponents = ['VolunteerHeader'];

const Placeholder = ({
  name,
  rendering,
  sitecoreContext = {},
  placeholderData,
  ...otherProps
}) => {
  if (sitecoreContext.pageEditing) {
    //Switch out any components that are missing a datasource with the ErrorComponent
    rendering.placeholders[name].forEach((component) => {
      if (
        component.componentName &&
        !component.placeholders &&
        !component.fields &&
        !exceptionComponents.includes(component.componentName)
      ) {
        component.fields = {
          componentName: component.componentName,
        };
        component.componentName = 'ErrorComponent';
      }
    });
  } else {
    //Filter out any components that are missing a datasource on live site
    rendering.placeholders[name] = rendering.placeholders[name].filter(
      (component) => {
        if (
          component.placeholders ||
          component.fields ||
          exceptionComponents.includes(component.componentName)
        ) {
          return true;
        }
        console.error(`${component.componentName} is missing a datasource`);
        return false;
      },
    );
  }
  // pass placeholder data to components if it exists
  let _placeholderData = {};
  if (placeholderData && placeholderData.data) {
    _placeholderData = { ...placeholderData.data.item };
  } else {
    _placeholderData = placeholderData;
  }
  return (
    <JSSPlaceholder
      name={name}
      rendering={rendering}
      fields={{ placeholderData: { ..._placeholderData } }}
      {...otherProps}
    />
  );
};

export default withSitecoreContext()(Placeholder);
