import { useEffect } from "react";

const ThemeSelector = ({ onStylesLoaded, shouldLoadCDS, theme }) => {
  // load themes
  useEffect(() => {
    const stylePromiseArray = [];

    if (shouldLoadCDS) {
      stylePromiseArray.push(
        import(
          "../node_modules/@cochlear-design-system/foundation/dist/index.css"
        ),
      );
    }

    switch (theme) {
      case "cochlear-foundation": {
        stylePromiseArray.push(
          import(
            "./styles/themes/cochlear-foundation/cochlear-foundation.scss"
          ),
        );
        break;
      }

      case "cochlear-rebrand": {
        stylePromiseArray.push(
          import("./styles/themes/cochlear-rebrand/cochlear-rebrand.scss"),
        );
        break;
      }

      case "standard-of-care": {
        stylePromiseArray.push(
          import("./styles/themes/standard-of-care/standard-of-care.scss"),
        );
        break;
      }
    }

    if (stylePromiseArray.length > 0) {
      Promise.all(stylePromiseArray).then(onStylesLoaded);
    }
  }, []);

  return null;
};

export default ThemeSelector;
